<template>
  <div>
    <list
      ref="refIncidentsList"
      sort-by="incidenciasId"
      refetch-records-name="incidencias"
      key-field="IncidenciaId"
      :actions="actions"
      :filters.sync="filters"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :refetch-records="fetchIncidents"
      :sort-dir-desc="false"
    >
      <template #cell(fechaInicio)="data">
        <b-media v-if="stateTime.value" vertical-align="center">
          {{ data.item.fechaInicio | formatDateTime }}
        </b-media>
        <b-media v-else vertical-align="center">
          {{ data.item.fechaInicio | formatDate }}
        </b-media>
      </template>
      <template #cell(fechaFin)="data">
        <b-media v-if="stateTime.value" vertical-align="center">
          {{ data.item.fechaFin | formatDateTime }}
        </b-media>
        <b-media v-else vertical-align="center">
          {{ data.item.fechaFin | formatDate }}
        </b-media>
      </template>
    </list>
  </div>
</template>

<script>
import {
  BMedia,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import helpDeskService from '@/services/helpDesk.service'
import List from '@/components/List.vue'
import { ref } from '@vue/composition-api'

export default {
  components: {
    List,
    BMedia,
  },
  setup() {
    const {
      fetchIncidents,
    } = helpDeskService()
    const refIncidentsList = ref(null)
    const dateTimeFormater = JSON.parse(localStorage.getItem('stateTime'))
    if (!dateTimeFormater.find(e => e.key === 'formatDateIncidents')) {
      dateTimeFormater.push({ key: 'formatDateIncidents', value: false })
      localStorage.setItem('stateTime', JSON.stringify(dateTimeFormater))
    }
    const stateTime = ref(dateTimeFormater.find(e => e.key === 'formatDateIncidents'))

    let actions = ref([])

    const changeStateTime = () => {
      stateTime.value.value = !stateTime.value.value
      localStorage.setItem('stateTime', JSON.stringify(dateTimeFormater))
      const index = actions.value.findIndex(e => e.name === 'dateAndHour')
      actions.value[index].value = stateTime.value.value
    }
    const filters = ref([
      {
        type: 'date',
        name: 'fechaDesde',
        label: i18n.t('Components.List.Filters.DateFrom'),
        value: '',
      },
      {
        type: 'date',
        name: 'fechaHasta',
        label: i18n.t('Components.List.Filters.DateTo'),
        value: '',
      },
      {
        type: 'checkbox',
        label: i18n.t('Components.List.Filters.FormatDate'),
        value: 0,
        options: [0, 1],
      },
    ])
    const tableColumns = [
      {
        key: 'incidenciaId',
        label: i18n.t('AppHelpDesk.id'),
        sortable: true,
        sortKey: 'IncidenciaId',
      },
      {
        key: 'titulo',
        label: i18n.t('AppHelpDesk.titleError'),
        sortable: true,
        sortKey: 'Titulo',
      },
      {
        key: 'fechaInicio',
        label: i18n.t('AppHelpDesk.columns.startDate'),
        sortable: false,
      },
      {
        key: 'fechaFin',
        label: i18n.t('AppHelpDesk.endDate'),
        sortable: false,
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
        sortable: false,
      },
    ]
    const tableActions = ref([
      {
        name: 'information',
        label: i18n.t('AppHelpDesk.editIncident'),
        aclAction: 'read',
        aclResource: 'MesaAyuda',
        routeName: 'apps-tickets-incidents-edit',
        params: ['incidenciaId'],
        icon: 'CodesandboxIcon',
      },
    ])
    actions = ref([
      {
        label: i18n.t('AppHelpDesk.newIncident'),
        aclAction: 'create',
        aclResource: 'MesaAyuda',
        routeName: 'apps-tickets-incidents-add',
        icon: 'PlusCircleIcon',
      },
      {
        name: 'dateAndHour',
        aclAction: 'read',
        aclResource: 'AccionesMejora',
        icon: 'CalendarIcon',
        value: stateTime.value.value,
        click: changeStateTime,
      },
    ])
    return {
      refIncidentsList,
      filters,
      tableColumns,
      tableActions,
      actions,
      fetchIncidents,
      stateTime,
    }
  },
}
</script>
